import React, { FC } from "react";
import styles from "./Button.module.scss";
import { Button as ButtomMaterial } from "@material-ui/core";

export interface IButtonProps {
  //propertys
  id?: string;
  fullWidth?: boolean;
  variant?: "outlined" | "text" | "contained";
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  className?: string;
  onClick?: () => void;
  autoFocus?: boolean;
  href?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  style?: React.CSSProperties | undefined
}
const Button: FC<IButtonProps> = ({
  children,
  fullWidth = true,
  variant = "contained",
  disabled,
  type,
  className,
  onClick,
  autoFocus,
  id,
  href,
  color = "primary",
  endIcon,
  startIcon,
  style
}) => {
  return (
    <ButtomMaterial
      endIcon={endIcon}
      startIcon={startIcon}
      id={id}
      type={type}
      disabled={disabled}
      variant={variant}
      fullWidth={fullWidth}
      size="small"
      onClick={onClick}
      autoFocus={autoFocus}
      href={href}      
      style={{
        textTransform: 'capitalize',
        ...style
      }}
      classes={{
        root: styles.root,
        contained: styles.botao,
        outlined: styles.botaoOutlined,
        text: styles.botaoText,
        disabled: styles.botaoDisabled,
        textPrimary: styles.primary,
        outlinedPrimary: styles.primary,
        containedPrimary: styles.primary,
        textSecondary: styles.secondary,
        outlinedSecondary: styles.secondary,
        containedSecondary: styles.secondary,
      }}
      className={className}
      color={color}
    >
      {children}
    </ButtomMaterial>
  );
};
export default Button;

import { ILocalRepository } from "modules/auth/application/interfaces/repository/ILocalRepository";
import { SystemIdentifier } from "modules/local/domain/enums/SystemIdentifier";
import { ILocal } from "modules/local/domain/models/ILocal";

export const LocalRepository = (): ILocalRepository => {

  const localKey = "@locals";
  const auth = "@auth";
  const currentLocalkey = "@localCurrent";

  const setLocals = (local: ILocal[]) => {
    //localStorage.setItem(localKey, JSON.stringify(local));
  };
  const getLocals = (): ILocal[] | null => {
    try {
      //const dataString = localStorage.getItem(localKey)
      const dataString = localStorage.getItem(auth);
      if (dataString) {
        const locals = JSON.parse(dataString)?.user?.locals as ILocal[];
        //const response = JSON.parse(dataString) as ILocal[];
        return locals;
      }
      else {
        return null;
      }
    } catch (error) {
      return null
    }
  };
  const removeLocals = () => {
    localStorage.removeItem(localKey);
  };



  const setCurrentLocal = (local: ILocal) => {
    localStorage.setItem(currentLocalkey, JSON.stringify(local));
  };
  const getCurrentLocal = (): ILocal | null => {
    try {
      const dataString = localStorage.getItem(currentLocalkey);
      if(dataString){
        const response = JSON.parse(dataString) as ILocal;
        return response;
      }else{
        return null
      }
    } catch(error) {
      console.error(error);
      return null
    }
  };
  const removeCurrentLocal = () => {
    localStorage.removeItem(currentLocalkey);
  };
  const startSaasGoogleAnalytics = (local: ILocal) => {
    if (process.env.REACT_APP_ENVIRONMENT_NAME !== 'development' && process.env.REACT_APP_ENVIRONMENT_NAME !== 'stage') {
      if (local.systemIdentifier === SystemIdentifier.Saas) {
        window.gtag('config', 'G-228WWHWS59');
      }
    }
  }

  return {
    setLocals,
    getLocals,
    removeLocals,
    setCurrentLocal,
    getCurrentLocal,
    removeCurrentLocal,
    startSaasGoogleAnalytics
  };
};


